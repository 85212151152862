import {
  selectFormCaptureSelected,
  selectFormCaptureConfigSelected,
  selectListSceneIdToOpenFormCapture,
  selectListHotspotIdToOpenFormCapture,
  selectListHotspotTypeToOpenFormCapture,
  selectListFormCaptureConfigCorrespondingToListSceneId,
  selectListFormCaptureConfigCorrespondingToListHotspotId,
  selectListFormCaptureConfigCorrespondingToListHotspotType,
  selectIsVisibleFormCapture,
  selectHotspotSelectedTemporary,
  selectFormCaptureConfigGlobal,
} from '@containers/Home/selectors';
import { actions } from '@containers/Home/slice';
import {
  I_FormCaptureConfig,
  I_Hotspot,
  I_Scene,
} from '@containers/Home/types';
import { KEY_COOKIE } from '@type/customType';
import { isServer } from '@utils/helper';
import { parse } from 'cookie';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  listFormCaptureOpenedCookie?: string;
}

export const useLeadFormCapture = ({
  listFormCaptureOpenedCookie = '[]',
}: Props) => {
  const dispatch = useDispatch();

  const isVisibleFormCapture = useSelector(selectIsVisibleFormCapture);

  const formCaptureSelected = useSelector(selectFormCaptureSelected);
  const formCaptureConfigSelected = useSelector(
    selectFormCaptureConfigSelected,
  );
  const formCaptureConfigGlobal = useSelector(selectFormCaptureConfigGlobal);
  const hotspotSelectedTemporary = useSelector(selectHotspotSelectedTemporary);

  const listSceneIdToOpenFormCapture = useSelector(
    selectListSceneIdToOpenFormCapture,
  );
  const listFormCaptureConfigCorrespondingToListSceneId = useSelector(
    selectListFormCaptureConfigCorrespondingToListSceneId,
  );

  const listHotspotIdToOpenFormCapture = useSelector(
    selectListHotspotIdToOpenFormCapture,
  );
  const listFormCaptureConfigCorrespondingToListHotspotId = useSelector(
    selectListFormCaptureConfigCorrespondingToListHotspotId,
  );

  const listHotspotTypeToOpenFormCapture = useSelector(
    selectListHotspotTypeToOpenFormCapture,
  );
  const listFormCaptureConfigCorrespondingToListHotspotType = useSelector(
    selectListFormCaptureConfigCorrespondingToListHotspotType,
  );

  const cookies = useCallback(
    () => (!isServer ? parse(document.cookie) : {} || {}),
    [],
  );

  const onGetListFormCaptureIdOpened = useCallback(
    () =>
      JSON.parse(
        (isServer
          ? listFormCaptureOpenedCookie
          : cookies()[KEY_COOKIE.LIST_FORM_CAPTURE_OPENED] || '[]') || '[]',
      ),
    [cookies, listFormCaptureOpenedCookie],
  );

  const onOpenFormCapture = useCallback(
    (
      formCaptureConfig: I_FormCaptureConfig,
      isEventHotspotClick = false,
      hotspot = undefined,
    ) => {
      const listFormCaptureOpened = onGetListFormCaptureIdOpened();
      const isOpened =
        listFormCaptureOpened.indexOf(formCaptureConfig?.form) > -1;
      if (!isOpened) dispatch(actions.setIsVisibleFormCapture(true));
      else if (isEventHotspotClick && hotspot) {
        dispatch(actions.setHotspotSelected(hotspot));
        dispatch(actions.setHotspotSelectedTemporary(undefined));
      } else dispatch(actions.setIsVisibleFormCapture(false));
    },
    [dispatch, onGetListFormCaptureIdOpened],
  );

  const onCloseFormCapture = useCallback(() => {
    const listFormCaptureOpened = onGetListFormCaptureIdOpened();
    if (listFormCaptureOpened.indexOf(formCaptureSelected?.id) === -1) {
      if (!listFormCaptureOpened.length)
        document.cookie = `${
          KEY_COOKIE.LIST_FORM_CAPTURE_OPENED
        }=${JSON.stringify([formCaptureSelected?.id])}; path=/`;
      else
        document.cookie = `${
          KEY_COOKIE.LIST_FORM_CAPTURE_OPENED
        }=${JSON.stringify([
          ...listFormCaptureOpened,
          formCaptureSelected?.id,
        ])}; path=/`;
    }
    dispatch(actions.setIsVisibleFormCapture(false));
    if (hotspotSelectedTemporary) {
      dispatch(actions.setHotspotSelected(hotspotSelectedTemporary));
      dispatch(actions.setHotspotSelectedTemporary(undefined));
    }
  }, [
    dispatch,
    formCaptureSelected,
    hotspotSelectedTemporary,
    onGetListFormCaptureIdOpened,
  ]);

  const onCheckShowFormCaptureOnHotspot = useCallback(
    (item: I_Hotspot) => {
      const indexOnListHotspotId = listHotspotIdToOpenFormCapture.indexOf(
        item.id,
      );
      const indexOnListHotspotType = listHotspotTypeToOpenFormCapture.indexOf(
        item.type,
      );
      if (indexOnListHotspotId !== -1) {
        onOpenFormCapture(
          listFormCaptureConfigCorrespondingToListHotspotId[
            indexOnListHotspotId
          ],
          true,
          item,
        );
        dispatch(
          actions.setFormCaptureConfigSelelected(
            listFormCaptureConfigCorrespondingToListHotspotId[
              indexOnListHotspotId
            ],
          ),
        );
        dispatch(actions.setHotspotSelectedTemporary(item));
      } else if (indexOnListHotspotType !== -1) {
        onOpenFormCapture(
          listFormCaptureConfigCorrespondingToListHotspotType[
            indexOnListHotspotType
          ],
          true,
          item,
        );
        dispatch(
          actions.setFormCaptureConfigSelelected(
            listFormCaptureConfigCorrespondingToListHotspotType[
              indexOnListHotspotType
            ],
          ),
        );
        dispatch(actions.setHotspotSelectedTemporary(item));
      } else dispatch(actions.setHotspotSelected(item));
    },
    [
      dispatch,
      onOpenFormCapture,
      listHotspotIdToOpenFormCapture,
      listHotspotTypeToOpenFormCapture,
      listFormCaptureConfigCorrespondingToListHotspotId,
      listFormCaptureConfigCorrespondingToListHotspotType,
    ],
  );

  const onCheckShowFormCaptureOnScene = useCallback(
    (item: I_Scene) => {
      const index = listSceneIdToOpenFormCapture.findIndex(
        sceneId => sceneId === item.id,
      );
      if (index > -1) {
        onOpenFormCapture(
          listFormCaptureConfigCorrespondingToListSceneId[index],
        );
        dispatch(
          actions.setFormCaptureConfigSelelected(
            listFormCaptureConfigCorrespondingToListSceneId[index],
          ),
        );
      }
    },
    [
      dispatch,
      onOpenFormCapture,
      listSceneIdToOpenFormCapture,
      listFormCaptureConfigCorrespondingToListSceneId,
    ],
  );

  const onCheckShowFormCaptureOnProject = useCallback(() => {
    if (
      formCaptureConfigSelected &&
      formCaptureConfigSelected?.type === 'project'
    )
      setTimeout(() => {
        onOpenFormCapture(formCaptureConfigSelected);
      }, (formCaptureConfigSelected.waitTime || 2) * 1000);
    else dispatch(actions.setIsVisibleFormCapture(false));
  }, [dispatch, formCaptureConfigSelected, onOpenFormCapture]);

  return useMemo(
    () => ({
      onOpenFormCapture,
      onCloseFormCapture,
      formCaptureSelected,
      isVisibleFormCapture,
      formCaptureConfigGlobal,
      formCaptureConfigSelected,
      onCheckShowFormCaptureOnScene,
      onCheckShowFormCaptureOnHotspot,
      onCheckShowFormCaptureOnProject,
    }),
    [
      onOpenFormCapture,
      onCloseFormCapture,
      formCaptureSelected,
      isVisibleFormCapture,
      formCaptureConfigGlobal,
      formCaptureConfigSelected,
      onCheckShowFormCaptureOnScene,
      onCheckShowFormCaptureOnHotspot,
      onCheckShowFormCaptureOnProject,
    ],
  );
};
